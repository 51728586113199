var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main-container',{attrs:{"icon":_vm.$route.meta.icon,"title":_vm.$route.meta.title},scopedSlots:_vm._u([{key:"tools",fn:function(){return [_c('emc-excel-import-icon',{attrs:{"param":"work_load_histories"},on:{"onCompleted":function($event){return _vm.getData()}}}),(_vm.user && _vm.user.is_master)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary darken-1","icon":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.type='availability'; _vm.showUpdating = true;}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-flash-auto")])],1)]}}],null,false,3045708896)},[_c('span',[_vm._v(" Atualizar Disponibilidade")])]):_vm._e(),(_vm.user && _vm.user.is_master)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"purple darken-1","icon":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.type='activity'; _vm.showUpdating = true;}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-flash-auto")])],1)]}}],null,false,786951326)},[_c('span',[_vm._v(" Atualizar Atividade")])]):_vm._e(),(_vm.user && _vm.user.is_master)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"amber darken-1","icon":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.type='work_load'; _vm.showUpdating = true;}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-flash-auto")])],1)]}}],null,false,2296939868)},[_c('span',[_vm._v(" Atualizar Carga Horária")])]):_vm._e(),_c('s-icon-report-download',{attrs:{"report":"43","show-message":""}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"indigo darken-1","icon":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.showForm = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v(" Novo Cadastro ")])])]},proxy:true}])},[_c('emc-loader-progress',{attrs:{"show":_vm.loadingProgress}}),_c('emc-table-items',{attrs:{"headers":_vm.headers,"params":_vm.params,"collections":_vm.collections,"meta":_vm.meta,"itemDelete":_vm.itemDelete,"itemEdit":_vm.model,"loading":_vm.loading},on:{"update:params":function($event){_vm.params=$event},"update:collections":function($event){_vm.collections=$event},"update:meta":function($event){_vm.meta=$event},"update:itemDelete":function($event){_vm.itemDelete=$event},"update:item-delete":function($event){_vm.itemDelete=$event},"update:itemEdit":function($event){_vm.model=$event},"update:item-edit":function($event){_vm.model=$event}},scopedSlots:_vm._u([{key:"item.period",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.year + '/' + item.period)+" ")]}}])}),_c('sys-control-panel-work-load-history-register',{attrs:{"model":_vm.model,"show":_vm.showForm},on:{"onCompleted":function($event){return _vm.getData()},"update:show":function($event){_vm.showForm=$event}}}),_c('emc-alert-modal',{attrs:{"text":_vm.textDelete,"disabled":_vm.deleting,"show":_vm.show},on:{"update:show":function($event){_vm.show=$event}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"color":"error","loading":_vm.deleting,"disabled":_vm.deleting},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.deleteItem()}}},[_vm._v("Excluir "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v("mdi-delete")])],1)]},proxy:true}])}),_c('emc-alert-snackbar',{attrs:{"show":!! _vm.message.length,"message":_vm.message,"color":_vm.error ? 'error' : 'success'}}),_c('emc-alert-modal',{attrs:{"text":"Essa ação irá atualizar todas as informações do período atual. Deseja continuar?","disabled":_vm.updating,"show":_vm.showUpdating},on:{"update:show":function($event){_vm.showUpdating=$event}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"color":"success","loading":_vm.updating,"disabled":_vm.updating},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.generate()}}},[_vm._v("Atualizar Dados"),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v("mdi-refresh-circle")])],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }